import React, {useContext, useEffect, useState} from "react";
import classNames from "classnames";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useNavigate } from 'react-router-dom';
import Hero from "../../components/hero/Hero";
import Introduction from "../../components/introduction";
import Achievements from "../../components/achievements";
import Capacities from "../../components/capacities";
import {menuItem} from "../../conts";
import Value from "../../components/value";
import Slider from "react-slick";
import './style.scss';
import ContactComponent from "../../components/contactComponent";
import firestoreDatasContext from "../../components/firestoreDatas/firestoreDatasContext";
import HeaderDesktop from "../../components/header/desktop";

let settings = {
  autoplay: true,
  dots: false,
  infinite: true,
  autoplaySpeed: 1500,
  slidesToShow: 3,
  draggable: false,
  pauseOnHover: false
};

const HomeDark = ({
  target,
  tab = 0,
}) => {
  let navigate = useNavigate();
  const data = useContext(firestoreDatasContext);

  document.querySelector("body").classList.remove("rtl");

  const [tabIndex, setTabIndex] = useState(tab);
  const [activeStarIndex, setActiveStarIndex] = useState("");

  const handleStarActiveChange = (e) => {
    const nextActiveStarIndex = e.target.getAttribute("data-index");

    if (nextActiveStarIndex) {
      setActiveStarIndex(nextActiveStarIndex);
    }
  }

  const handleStarClick = (e) => {
    const index = parseInt(e.target.getAttribute("data-index"), 10);

    if (!isNaN(index)) {
      setTabIndex(index);
      setActiveStarIndex("");
    }
  }

  const joinUs = () => {
    window.location.href = "mailto:info@hydrae.lu?subject=Prise de contact HYDRAE&body=Veuillez indiquer votre nom, prenom, joignez-y une lettre de motivation et un CV";
  }

  const handleResetStarActive = () => setActiveStarIndex("");

  useEffect(() => {
    navigate(menuItem[tabIndex].route);
  }, [tabIndex, navigate]);

  return (
    <div className="yellow">
      {
        tabIndex !== 0 && (
            <div className='home-language-switcher'>
              <HeaderDesktop light={tabIndex !== 0}/>
            </div>
          )
      }
      <Tabs selectedIndex={tabIndex} onSelect={(tabIndex) => setTabIndex(tabIndex)}>
        <div className="header">
          {
              tabIndex !== 0 ? (
                  <TabList className="icon-menu revealator-slideup revealator-once revealator-delay1">
                    {menuItem.map((item, i) => (
                        <Tab className={classNames("icon-box", { active: activeStarIndex === i.toString() })} key={i}>
                          <i className={`fa ${item.icon}`} />
                          <h2>{data.lang === "fr" ? item.menuName : item.menuNameEn}</h2>
                        </Tab>
                    ))}
                    <li className={'icon-box'} onClick={() => joinUs()}>
                      <i className={`fa fa-handshake`} />
                    </li>
                  </TabList>
              ) : (
                  <div className="show-mobile">
                    <TabList className="icon-menu revealator-slideup revealator-once revealator-delay1">
                      {menuItem.map((item, i) => (
                          <Tab className={classNames("icon-box", { active: activeStarIndex === i.toString() })} key={i}>
                            <i className={`fa ${item.icon}`} />
                            <h2>{data.lang === "fr" ? item.menuName : item.menuNameEn}</h2>
                          </Tab>
                      ))}
                      <li className={'icon-box'} onClick={() => joinUs()}>
                        <i className={`fa fa-handshake`} />
                      </li>
                    </TabList>
                  </div>
              )
          }
        </div>
        {/* End Menu Content */}

        <div className="tab-panel_list">
          {/* Hero Content Starts */}
          <TabPanel className="home ">
            <div
              className="container-fluid main-container container-home p-0 "
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              {/* <div className="color-block d-none d-lg-block" /> */}
              <Hero
                onStarClick={handleStarClick}
                activeStarIndex={activeStarIndex}
                onResetStarActive={handleResetStarActive}
                onStarActiveChange={handleStarActiveChange}
              />
            </div>
          </TabPanel>
          {/* Hero Content Ends */}

          {/* Presentation */}
          <TabPanel className="about">
            <div data-aos="fade-up" data-aos-duration="1200">
              <div className="title-section text-left text-sm-center">
                <h1>
                  <span>
                  Presentation
                  </span>
                </h1>
                <span className="title-bg">Presentation</span>
              </div>
              {/* End title */}
              <Introduction data={data} />
            </div>
          </TabPanel>
          {/* About Content Ends */}

          {/* Realisations */}
          <TabPanel className="blog">
            <div
              className="title-section text-left text-sm-center"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <h1>
                <span>
                { data.lang === "fr" ? "Nos réalisations" : "Our achievements" }
                </span>
              </h1>
              <span className="title-bg">{ data.lang === "fr" ? "réalisations" : "achievements" }</span>
            </div>
            <div
                className="container"
                data-aos="fade-up"
                data-aos-duration="1200"
            >
              <div className="pb-50">
                <Capacities target={'realisations'}/>
              </div>
            </div>
          </TabPanel>
          {/* Portfolio Content Ends */}

          {/* Moyens */}
          <TabPanel className="blog">
            <div
                className="title-section text-left text-sm-center "
                data-aos="fade-up"
                data-aos-duration="1200"
            >
              <h1>
                <span>
                { data.lang === "fr" ? "Nos moyens techniques" : "Our technical means" }
                </span>
              </h1>
              <span className="title-bg">{ data.lang === "fr" ? "Moyens techniques" : "Technical means" }</span>
            </div>
            <div
                className="container"
                data-aos="fade-up"
                data-aos-duration="1200"
            >
              {/*  Articles Starts  */}
              <div className="pb-50">
                <Capacities target={'technicalAssets'}/>
              </div>
              {/* Articles Ends */}
            </div>
          </TabPanel>
          {/* Blog Content Ends */}

          {/* Blog Content Starts */}
          <TabPanel className="blog">
            <div
                className="title-section text-left text-sm-center "
                data-aos="fade-up"
                data-aos-duration="1200"
            >
              <h1>
                <span>{ data.lang === "fr" ? "Nos clients" : "Our clients" }</span>
              </h1>
              <span className="title-bg">Clients</span>
            </div>
            <div
                className="container"
                data-aos="fade-up"
                data-aos-duration="1200"
            >
              {/*  Articles Starts  */}
              <div className="row pb-50">
                <Achievements source={'clients'}/>
              </div>
              {/* Articles Ends */}
            </div>
          </TabPanel>
          {/* Blog Content Ends */}

          {/* Contact Content Starts */}
          <TabPanel className="contact">
            <div
              className="title-section text-left text-sm-center"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <h1>
                <span>{data.lang === "fr" ? "Notre valeur ajoutée" : "Our added value"}</span>
              </h1>
              <span className="title-bg">{ data.lang === "fr" ? "Notre valeur ajoutée" : "Our added value" }</span>
            </div>
            <div
              className="container"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="row">
                {/*  Left Side Starts */}
                <div className="col-12 col-sm-12">
                  <Value />
                </div>
              </div>
            </div>
            {/* End .container */}
          </TabPanel>
          {/* Contact Content Ends */}
          {
            tabIndex !== 0 && (
                  <TabPanel className="contact">
                    <ContactComponent map={data && data.home && data.home.googleMapImageUrl}/>
                  </TabPanel>
              )
          }
        </div>
      </Tabs>
      {
        tabIndex === 0 && (
          <div className="modal__container">
            <figure className="modal__img">
              <Slider {...settings}>
                {
                  data && data.home && data.home.imageUrls && data.home.imageUrls.map((image) => (
                    <div>
                      <div style={{backgroundImage: `url(${image})`}} className="carousel-picture" />
                    </div>
                  ))
                }
              </Slider>
            </figure>
          </div>
        )
      }
      {
        tabIndex === 0 && (
            <div className="contact contact__container">
              <ContactComponent map={data && data.home && data.home.googleMapImageUrl}/>
            </div>
          )
      }
    </div>
  );
};

export default HomeDark;
