import {sentences} from "../conts";
import {useContext} from "react";
import firestoreDatasContext from "../components/firestoreDatas/firestoreDatasContext";

export const computeFirestoreData = (data) => {
  if (!data) {
    return data;
  }

  Object.keys(data).forEach((key) => {
    if (typeof data[key].toDate !== 'undefined') {
      data[key] = data[key].toDate();
    }
  });

  return data;
};

export const GetTranslation = (key) => {
  const data = useContext(firestoreDatasContext);
  return sentences.filter((obj) => obj.key === key)[0][data.lang === "fr" ? 'menuName':'menuNameEn' ]

}
