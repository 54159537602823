import React from "react";
import pointer  from './contactComponent/pointer.png';
import './contactComponent/style.scss';
import {GetTranslation} from "../helpers";
const Address = ({map}) => {
  return (
    <>
      <p className="open-sans-font custom-span-contact position-relative">
        <img alt="logo" src={pointer} className={'contactPointer'}/>
        <span className="d-block">Adresse</span>70-72 Rue Muehlenweg, L-2155 Luxembourg
      </p>
        {
            map && (
                <div>
                    <img src={map} style={{maxWidth: 400, width: '100%'}} alt="map"/>
                </div>
            )
        }
      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-envelope-open position-absolute" />
        <span className="d-block">Mail</span>{" "}
        <a href="mailto:steve@mail.com">info@hydrae.lu</a>
      </p>
      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-phone-square position-absolute" />
        <span className="d-block"> { GetTranslation('mail') }</span>{" "}
        <a href="Tel: +352 621 714 005">+352 621 714 005</a>
      </p>
        <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa-brands fa-linkedin position-absolute" />
        <span className="d-block"> { GetTranslation('ourLinkedin') }</span>{" "}
        <a rel={'noreferrer'} target={'_blank'} href="https://www.linkedin.com/company/hydrae-mechanical-structural-project/?miniCompanyUrn=urn%3Ali%3Afs_miniCompany%3A71512849&lipi=urn%3Ali%3Apage%3Ad_flagship3_detail_base%3B%2BTtCPjecSsmI8TAYipA0BA%3D%3D">HYDRAE</a>
      </p>
    </>
  );
};

export default Address;
