import React from "react";
import {Tab, TabList} from "react-tabs";
import {menuItem} from "../../conts";

const Header = () => {
    return (
        <div className="header">
            <TabList className=" icon-menu  revealator-slideup revealator-once revealator-delay1">
                {menuItem.map((item, i) => (
                    <Tab className="icon-box" key={i} onClick={() => window.location.href = (item.route)}>
                        <i className={`fa ${item.icon}`} />
                        <h2>{item.menuName}</h2>
                    </Tab>
                ))}
            </TabList>
        </div>
    );
};

export default Header;
