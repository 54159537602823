import React, {useContext, useState} from "react";
import Modal from "react-modal";
import cancelImg from "../../assets/img/cancel.svg";
import CapacitiesQuote from "../../assets/img/blog/quote.svg";
import { useNavigate } from "react-router-dom";
import '../../style.scss';
import firestoreDatasContext from "../firestoreDatas/firestoreDatasContext";

Modal.setAppElement("#root");

const Capacities = ({item, target}) => {
    const data = useContext(firestoreDatasContext);
    let navigate = useNavigate();
    const source = item || (data && data[target]);
    const [ displayContent, setDisplayContent ] = useState();

    console.log('--- source', source);
    return (
        <div className="row">
            {source && source.map((item, index) => (
                <div
                    key={`item-${index}`}
                    className="col-12 col-md-6 col-lg-6 col-xl-4 mb-30"
                >
                    <article
                        className="post-container"
                        //onClick={() => setDisplayContent(index + 1)}
                        onClick={() => navigate(`/${target === 'technicalAssets' ? 'moyens-techniques' : target === 'clients' ? 'clients' : 'realisation'}/${item.id}`)}
                    >
                        <div className="post-thumb"  style={{aspectRatio: 1, display: 'flex', alignItems: "center",backgroundImage: `url(${item.imageUrl})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                        backgroundColor: "#000"}}>
                        </div>
                        <div className="post-content">
                            <div className="entry-header">
                                <h3>{item.title}</h3>
                            </div>
                            <div className="entry-content open-sans-font">
                                {
                                    item.summary
                                }
                                {
                                    /*
                                        <div
                                            dangerouslySetInnerHTML={{__html: item && item.content.slice(0, 200)}}
                                        />
                                     */
                                }
                            </div>
                        </div>
                    </article>
                    <Modal
                        isOpen={!!displayContent}
                        onRequestClose={() => setDisplayContent(null)}
                        contentLabel="My dialog"
                        className="custom-modal dark"
                        overlayClassName="custom-overlay dark"
                        closeTimeoutMS={500}
                    >
                        <div>
                            <button
                                className="close-modal"
                                onClick={() => setDisplayContent(null)}
                            >
                                <img src={cancelImg} alt="close icon" />
                            </button>
                            <div className="box_inner Capacities-post modal-container">
                                <article>
                                    <div className="title-section text-left text-sm-center">
                                        <h1>
                                            <span>{source && source[displayContent - 1] && source[displayContent - 1].title}</span>
                                        </h1>
                                        <span className="title-bg">Réalisations</span>
                                    </div>
                                    <h1><span>{source && source[displayContent - 1] && source[displayContent - 1].title}</span></h1>

                                    <div
                                        className="d-block position-relative overflow-hidden"
                                        style={{
                                            backgroundImage: `url(${source && source[displayContent - 1] && source[displayContent - 1].imageUrl})`,
                                            height: 450,
                                            backgroundSize: "cover",
                                            backgroundPosition: "center"
                                        }}
                                    />
                                    <div className="Capacities-excerpt open-sans-font pb-5">
                                        <p>{source && source[displayContent - 1] && source[displayContent - 1].title}</p>
                                        <div className="quotebox">
                                            <div className="icon">
                                                <img src={CapacitiesQuote} alt="Capacities quote" />
                                            </div>
                                            <div
                                                dangerouslySetInnerHTML={{__html: source && source[displayContent - 1] && source[displayContent - 1].content}}
                                            />
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </Modal>
                </div>
            ))}
        </div>
    );
};

export default Capacities;
