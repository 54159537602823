import React, {useContext} from "react";
import LanguageSwitcher from "../LanguageSwitcher";
import './style.scss';
import {menuItem} from "../../conts";
import firestoreDatasContext from "../firestoreDatas/firestoreDatasContext";
const Menu = ({label, link, light}) => {
    return (
        <div className={light ? 'header-desktop__menu__item header-desktop__menu__item--light' : 'header-desktop__menu__item'}>
            <a href={link}>{ label }</a>
        </div>
    );
};

const HeaderDesktop = ({ light }) => {
    const data = useContext(firestoreDatasContext);

    return (
        <div className={light ? "header-desktop header-desktop--light" : "header-desktop"}>
            <div className="header-desktop__menu">
                {
                    menuItem.map((menu) => (
                        <Menu label={data.lang === "fr" ? menu.menuName : menu.menuNameEn} link={menu.route} light={light}/>
                    ))
                }
            </div>
            <div className="header-desktop__languages">
                <LanguageSwitcher />
            </div>
        </div>
    );
};

export default HeaderDesktop;
