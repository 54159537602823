import React, { useContext } from "react";
import {
    useParams,
    useNavigate,
} from "react-router-dom";
import './style.scss';
import Header from "../../components/header";
import firestoreDatasContext from "../../components/firestoreDatas/firestoreDatasContext";

const Presentation = ({
    tab,
    target
}) => {
    let { id } = useParams();
    const data = useContext(firestoreDatasContext);
    const source = data && data[target];
    const item = source && source.filter((target) => target.id === id);
    let navigate = useNavigate();

    return (
        <>
            <Header />
            <div className="detail-container">
                <div className="icon-container" onClick={() => navigate(-1)}>
                    <div className="icon">
                        <i className="fa fa-caret-left" />
                    </div> Retour
                </div>
                <div className="row">
                    {item && item.map((item, index) => (
                        <div
                            key={`item-${index}`}
                            className="col-12 col-md-12 col-lg-12 col-xl-12 mb-30"
                        >
                            <article
                                className="post-container"
                                //onClick={() => setDisplayContent(index + 1)}
                                onClick={() => navigate(`/${target === 'technicalAssets' ? 'moyens-techniques' : 'realisation'}/${item.id}`)}
                            >
                                <div className="post-thumb">
                                    <div
                                        className="d-block position-relative overflow-hidden"
                                        style={{
                                            backgroundImage: `url(${item.imageUrl})`,
                                            height: 450,
                                            backgroundSize: "cover",
                                            backgroundPosition: "center"
                                        }}
                                    />
                                </div>
                                <div className="post-content post-content-large">
                                    <div className="entry-header">
                                        <h3>{item.title}</h3>
                                    </div>
                                    <div className="entry-content open-sans-font">
                                        <div
                                            dangerouslySetInnerHTML={{__html: item && item.content.slice(0, 200)}}
                                        />
                                    </div>
                                </div>
                            </article>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Presentation;
