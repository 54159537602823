export const menuItem = [
    { icon: "fa-home", menuName: "Accueil", menuNameEn: "Home", route: '/' },
    { icon: "fa-person-chalkboard", menuName: "Présentation", menuNameEn: "Introducing", route: '/presentation' },
    { icon: "fa-camera", menuName: "Réalisations", menuNameEn: "Achievements", route: '/realisations' },
    { icon: "fa-hammer", menuName: "Moyens techniques", menuNameEn: "Technical means", route: '/moyens-techniques' },
    { icon: "fa-users", menuName: "Nos clients", menuNameEn: "Our clients", route: '/clients' },
    { icon: "fa-chart-line", menuName: "Notre valeur ajoutée", menuNameEn: "Our added value", route: '/valeur-ajoutee' },
    { icon: "fa-at", menuName: "Contactez-nous", menuNameEn: "Contact-us", route: '/contact' },
];
export const sentences = [
    { key: "contactUs", menuName: "Contactez-nous", menuNameEn: "Contact-us" },
    { key: "contactUsText", menuName: "Contactez-nous, notre équipe vous répondra au plus vite", menuNameEn: "Contact us, our team will answer you as soon as possible" },
    { key: "address", menuName: "ADRESSE", menuNameEn: "ADDRESS" },
    { key: "mail", menuName: "APPELEZ-NOUS", menuNameEn: "CALL US" },
    { key: "ourLinkedin", menuName: "NOTRE LINKEDIN", menuNameEn: "OUR LINKEDIN" },
    { key: "consult", menuName: "CONSULTEZ ÉGALEMENT", menuNameEn: "SEE ALSO" },
    { key: "partners", menuName: "La liste de nos sites partenaires", menuNameEn: "The list of our partner sites" },
    { key: "ourPartner", menuName: "Notre partenaire", menuNameEn: "Our partner" },
    { key: "send", menuName: "Envoyer message", menuNameEn: "Send message" },
    { key: "firstNamePlaceholder", menuName: "Votre nom", menuNameEn: "Your name" },
    { key: "emailPlaceholder", menuName: "Votre email", menuNameEn: "Your eMail" },
    { key: "subjectPlaceholder", menuName: "Sujet", menuNameEn: "Subject" },
    { key: "companyPlaceholder", menuName: "Société", menuNameEn: "Company" },
    { key: "MessagePlaceholder", menuName: "Message", menuNameEn: "Message" },
]
