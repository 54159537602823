import React from "react";

import Address from "../Address";
import Contact from "../Contact";
import './style.scss';
import {GetTranslation} from "../../helpers";

const ContactComponent = (props) => {
    return (
        <div>
            <div
                className="title-section text-left text-sm-center"
                data-aos="fade-up"
                data-aos-duration="1200"
            >
                <h1>
                   <span>{ GetTranslation('contactUs') }</span>
                </h1>
                <span className="title-bg">{ GetTranslation('contactUs') }</span>
            </div>
            <div
                className="container"
            >
                <div className="row">
                    {/*  Left Side Starts */}
                    <div className="col-12 col-lg-4">
                        <h3 className="text-uppercase custom-title mb-0 ft-wt-600 pb-3">
                            { GetTranslation('contactUs') }
                        </h3>
                        <p className="open-sans-font mb-4">
                            { GetTranslation('contactUsText') }
                        </p>
                        <Address map={props.map} />
                        {/* End Address */}
                    </div>
                    {/* Left Side Ends */}

                    {/*  Contact Form Starts  */}
                    <div className="col-12 col-lg-8">
                        <Contact />
                    </div>
                    {/*  Contact Form Ends */}
                </div>
                {/*
                <div className="row">
                    <div className="col-12 col-lg-12">
                        <h3 className="text-uppercase custom-title mb-0 ft-wt-600 pb-3" style={{marginTop: 20}}>
                            { GetTranslation('consult') }
                        </h3>
                        <p className="open-sans-font mb-4">
                            { GetTranslation('partners') }
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6 col-md-4 col-lg-4">
                        <a href={'/'} rel={"noreferrer"} className="open-sans-font mb-4 partner-part">
                            { GetTranslation('ourPartner') } xx <span>www.partenaire.com</span>
                        </a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-4">
                        <a href={'/'} rel={"noreferrer"}  className="open-sans-font mb-4 partner-part">
                            { GetTranslation('ourPartner') } xx <span>www.partenaire.com</span>
                        </a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-4">
                        <a href={'/'} rel={"noreferrer"}  className="open-sans-font mb-4 partner-part">
                            { GetTranslation('ourPartner') } xx <span>www.partenaire.com</span>
                        </a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-4">
                        <a href={'/'} rel={"noreferrer"}  className="open-sans-font mb-4 partner-part">
                            { GetTranslation('ourPartner') } xx <span>www.partenaire.com</span>
                        </a>
                    </div>

                </div>
                */}
            </div>
            {/* End .container */}
        </div>
    )
}

export default ContactComponent;
