import React, { useContext } from "react";
import StarField from "../starField/StarField"
import hydraBg from '../hydra/hydra.png';
import logo from '../headerTop/logo.png';
import './hero.css';
import firestoreDatasContext from "../firestoreDatas/firestoreDatasContext";
import HeaderDesktop from "../header/desktop";
import Video from "../video";

const Hero = ({
  onStarClick,
  activeStarIndex,
  onResetStarActive,
  onStarActiveChange,
}) => {
  const data = useContext(firestoreDatasContext);

  return (
    <>
      <div className="row home-details-container align-items-center">
        <StarField />
        <div className='home-language-switcher'>
          <HeaderDesktop />
        </div>
        <div className="home-details text-center">
          <div className="hydra-bg hydra-bg-mobile d-lg-none" style={{ background: `url(${hydraBg})`}} />
          <div className="home-details__img text-center">
            <img src={logo} className="hydra-bg-logo" alt="logo"/>
            <div className="kymberley-font cstm-outlined">{data && data.home && data.home.description}</div>
            {/* <p className="hydra-bg-indicator">{data.lang === "fr" ? "Cliquez sur les étoiles ci-dessous" : "Click on the stars below"}</p> */}
          </div>
          <Video />
        </div>

        {
          /*
            <div className="hydra-container">
            <Hydra
              onStarClick={onStarClick}
              activeStarIndex={activeStarIndex}
              onResetStarActive={onResetStarActive}
              onStarActiveChange={onStarActiveChange}
            />
          </div>
           */
        }

      </div>
    </>
  );
};

export default Hero;
