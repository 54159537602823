import React, { useRef } from "react";

import "./starField.css";
import useStarField from "./useStarField";


const StarField = () => {
  const canvas = useRef(null);

  useStarField(canvas);

  return (
      <div className='starfield-container'>
        <div className="nebula" />
        <canvas className="starfield" ref={canvas} />
      </div>
  );
};


export default StarField;
