export const starFieldSettings = {
  stars: [],
  shootingStars: [],
  withShootingStar: false,

  starsAngle: 37,
  layers: [
    { speed: 0.05, scale: 0.2, count: 100 },
    { speed: 0.02, scale: 0.5, count: 70 },
    { speed: 0.01, scale: 0.75, count: 30 },
  ],
  starsColors: [
    "#FEFACE",
    "#FFF3F2",
    "#AAA",
  ],

  shootingStarSpeed: { min: 5, max: 20 },
  shootingStarOpacityDelta: 0.01,
  shootingStarTimer: 0,
  shootingStarEmittingInterval: { min: 1000, max: 10000, value: 1000 },
  shootingStarLifeTime: 500,

  trailLengthDelta: 0.01,
  maxTrailLength: 300,
  starBaseRadius: 2,
  shootingStarRadius: 3,
};


/**
 * Class used for each particle of the StarField
 */
export class StarFieldParticle {
  /* ATTRIBUTES */

  x = 0;
  y = 0;
  color = "#FFFFFF";
  radius = 0;
  velocityX = 0;
  velocityY = 0;
  opacity = 0;
  trailLengthDelta = 0;
  isSpawning = true;
  isDying = false;
  isDead = false;


  /* LIFECYCLE */

  constructor(x, y, speed, direction) {
    this.x = x;
    this.y = y;
    this.velocityX = Math.cos(direction) * speed;
    this.velocityY = Math.sin(direction) * speed;
  }


  /* METHODS */

  update() {
    this.x += this.velocityX;
    this.y += this.velocityY;
  }

  getSpeed() {
    return Math.sqrt(this.velocityX * this.velocityX + this.velocityY * this.velocityY);
  }

  getHeading() {
    return Math.atan2(this.velocityY, this.velocityX);
  }

  setSpeed(speed) {
    const heading = this.getHeading();

    this.velocityX = Math.cos(heading) * speed;
    this.velocityY = Math.sin(heading) * speed;
  }

  setHeading(heading) {
    const speed = this.getSpeed();

    this.velocityX = Math.cos(heading) * speed;
    this.velocityY = Math.sin(heading) * speed;
  }
}


/**
 * Convert a line data to angle
 * @param {*} x1 - The coordinate of the line in x axis
 * @param {*} y1 - The coordinate of the line in y axis
 * @param {*} length - The length of the line
 * @param {*} radians - The radians of the line
 */
export const lineToAngle = (x1, y1, length, radians) => {
  const x2 = x1 + length * Math.cos(radians);
  const y2 = y1 + length * Math.sin(radians);

  return { x: x2, y: y2 };
};


/**
 * Random number between min and max
 * @param {*} min - Min value
 * @param {*} max - Max value
 */
export const randomRange = (min, max) => {
  return min + Math.random() * (max - min);
};


/**
 * Convert degrees to rads
 * @param {*} degrees - The degrees to convert
 * @returns
 */
export const degreesToRads = (degrees) => {
  return degrees / 180 * Math.PI;
};


/**
 * Kill a shooting star
 * @param {*} shootingStar - The shooting star to kill
 */
export const killShootingStar = (shootingStar) => {
  setTimeout(() => {
    shootingStar.isDying = true;
  }, starFieldSettings.shootingStarLifeTime);
};


/**
 * Create a shooting star
 */
export const createShootingStar = (x, y) => {
  const shootingStar = new StarFieldParticle(x, y, 0, 0);

  shootingStar.setSpeed(randomRange(starFieldSettings.shootingStarSpeed.min, starFieldSettings.shootingStarSpeed.max));
  shootingStar.setHeading(degreesToRads(Math.floor(Math.random() * 360)));

  shootingStar.radius = starFieldSettings.shootingStarRadius;
  shootingStar.opacity = 0;
  shootingStar.trailLengthDelta = 0;
  shootingStar.isSpawning = true;
  shootingStar.isDying = false;

  starFieldSettings.shootingStars.push(shootingStar);
};


export const createStar = (x, y, layer) => {
  const star = new StarFieldParticle(x, y, 0, 0);

  star.color = starFieldSettings.starsColors[Math.floor(randomRange(0, starFieldSettings.starsColors.length - 1))];
  star.radius = starFieldSettings.starBaseRadius * layer.scale;
  star.setSpeed(layer.speed);
  star.setHeading(degreesToRads(starFieldSettings.starsAngle));

  return star;
}

/**
 * Draw a star
 * @param {*} star - The star
 * @param {*} ctx - The canvas context
 */
export const drawStar = (star, ctx) => {

  ctx.beginPath();
  ctx.fillStyle = star.color;
  ctx.arc(star.x, star.y, star.radius, 0, Math.PI * 2, false);
  ctx.fill();
};


export const drawShootingStar = (shootingStar, ctx) => {
  const starLength = 5;
  const x = shootingStar.x;
  const y = shootingStar.y;
  const currentTrailLength = starFieldSettings.maxTrailLength * shootingStar.trailLengthDelta;
  const pos = lineToAngle(x, y, -currentTrailLength, shootingStar.getHeading());

  ctx.fillStyle = `rgba(255, 255, 255, ${shootingStar.opacity})`;

  ctx.beginPath();
  ctx.moveTo(x - 1, y + 1);

  ctx.lineTo(x, y, + starLength);
  ctx.lineTo(x + 1, y + 1);

  ctx.lineTo(x + starLength, y);
  ctx.lineTo(x + 1, y - 1);

  ctx.lineTo(x, y + 1);
  ctx.lineTo(x, y - starLength);

  ctx.lineTo(x - 1, y - 1);
  ctx.lineTo(x - starLength, y);

  ctx.lineTo(x - 1, y + 1);
  ctx.lineTo(x - starLength, y);

  ctx.closePath();
  ctx.fill();

  // trail
  ctx.fillStyle = `rgba(255, 221, 157, ${shootingStar.opacity})`;

  ctx.beginPath();
  ctx.moveTo(x - 1, y - 1);
  ctx.lineTo(pos.x, pos.y);
  ctx.lineTo(x + 1, y + 1);
  ctx.closePath();
  ctx.fill();
};
