import React from "react";
import './style.scss';
import video from '../../assets/video.mp4';

const Video = () => {
    return (
        <section className="video-part">
            <video autoPlay={true} loop={true} muted={true} playsinline={true} playsInline={true} src={video} type="video/mp4" />
        </section>
    );
};

export default Video;
