import React, {useContext, useEffect} from "react";
import ScrollToTop from "./components/ScrollToTop";
import AllRoutes from "./router/AllRoutes";
import AOS from "aos";
import "aos/dist/aos.css";
import { ToastContainer } from "react-toastify";
import {useLocation} from "react-router-dom";
import firestoreDatasContext from "./components/firestoreDatas/firestoreDatasContext";


const App = () => {
  // this for animation

    const location = useLocation();
    const data = useContext(firestoreDatasContext);
    //const navigate = useNavigate();

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  useEffect(() => {
    if(window.localStorage.getItem('lang') === "fr"){
        data.setLang('fr');
    }
  }, [data]);

    useEffect(() => {
        if(location && location.pathname) {
           // navigate(location.pathname);
        }
    }, [location]);

  return (
    <>
      <ScrollToTop />
      <AllRoutes />
      {/* End contactComponent */}
      <ToastContainer />
      {/* Same as */}
    </>
  );
};

export default App;
