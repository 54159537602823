import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/scss/main.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import firebase from "firebase/app";

import "firebase/storage";
import "firebase/firestore";
import FirestoreDatas from './components/firestoreDatas/FirestoreDatas';

firebase.initializeApp({
    apiKey: "AIzaSyC7TBtWqCrhfJqPlwZLC7JQfzsvuJE-AdM",
    authDomain: "hydrae-62ab0.firebaseapp.com",
    projectId: "hydrae-62ab0",
    storageBucket: "hydrae-62ab0.appspot.com",
    messagingSenderId: "643456281350",
    appId: "1:643456281350:web:93f9663410194cd7674c87"
});
firebase.firestore().settings({
    cacheSizeBytes: 20 * 1048576, // 20 mb
});
firebase.firestore().enablePersistence().catch(console.error);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
        <FirestoreDatas>
            <App />
        </FirestoreDatas>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
