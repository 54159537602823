import React, { useContext } from "react";
import '../../style.scss';
import firestoreDatasContext from "../firestoreDatas/firestoreDatasContext";
import bg from "../../assets/architect.jpg";

const Introduction = () => {
    const { presentation } = useContext(firestoreDatasContext);

    return (
        <section className="main-content">
            <div className="container">
                <div className="description">
                    <div className="row">
                        <div className="bg"
                             style={{backgroundImage: `url(${bg})`, display: 'flex', flexDirection: "column"}}>
                            <h3 className="text-uppercase custom-title mb-0 ft-wt-600 pb-3">
                                {presentation && presentation.title}
                            </h3>
                            <div
                              dangerouslySetInnerHTML={{__html: presentation && presentation.content}}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Introduction;
