import React from "react";
import HomeDark from "../views/all-home-version/HomeDark";
import NotFound from "../views/NotFound";
import { Routes, Route } from "react-router-dom";
import ContextProvider from "../Context/ContextProvider";
import ScrollTopBehaviour from "../components/ScrollTopBehaviour";
import Presentation from "../views/presentation";

const AllRoutes = () => {
  return (
    <>
      <ContextProvider>
        <ScrollTopBehaviour />
        <Routes>
          <Route path="/" element={<HomeDark tab={0} />} />
          <Route path="/presentation" element={<HomeDark tab={1} />} />
          <Route path="/realisations" element={<HomeDark tab={2} />} />
          <Route path="/moyens-techniques" element={<HomeDark tab={3} />} />
          <Route path="/clients" element={<HomeDark tab={4} />} />
          <Route path="/contact" element={<HomeDark tab={6} />} />
          <Route path="/valeur-ajoutee" element={<HomeDark tab={5} />} />
          <Route path="/realisation/:id" element={<Presentation tab={2} target="realisations" />} />
          <Route path="/moyens-techniques/:id" element={<Presentation tab={3} target="technicalAssets" />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ContextProvider>
    </>
  );
};

export default AllRoutes;
