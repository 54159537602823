import firebase from "firebase/app";
import { computeFirestoreData } from ".";
import { LangEnum } from "../components/firestoreDatas/langEnum";


const getSingleData = async (ref) => {
  const snapshot = await ref.get();

  return snapshot.exists && computeFirestoreData(snapshot.data())
};

const getListData = async (ref, orderType) => {
  let snapshots = await ref.get();
if (orderType){
  snapshots = await ref.orderBy(orderType, "asc").get();
}
else {
  snapshots = await ref.get();
}
  return snapshots.docs.map(doc => computeFirestoreData(doc.data()));
};

export const parseDatasWithTranslations = (datas, lang = LangEnum.Fr) => {
  const includeKey = `_${lang}`;

  const parseDocument = (obj, key) => {
    const value = obj[key];

    if (typeof value === "string" && key.includes(includeKey)) {
      obj[key.replace(includeKey, "")] = value;
    }
  }

  Object.keys(datas).forEach((collectionKey) => {
    const collection = datas[collectionKey];

    if (Array.isArray(collection)) {
      collection.forEach((document) => {
        Object.keys(document).forEach(documentKey => parseDocument(document, documentKey));
      });
    } else if (collection && typeof collection === "object") {
      Object.keys(collection).forEach((documentKey) => parseDocument(collection, documentKey));
    }
  });

  return datas;
}

export const getAllDatas = async () => {
  const db = firebase.firestore();
  const keys = ["home", "presentation", "realisations", "technicalAssets", "clients", "values"];

  const datas = await Promise.all([
    getSingleData(db.collection("pages").doc("home")),
    getSingleData(db.collection("pages").doc("presentation")),
    getListData(db.collection("realisations"), "position"),
    getListData(db.collection("technicalAssets")),
    getListData(db.collection("clients")),
    getListData(db.collection("values")),
  ]);

  console.log('Debug', datas)

  return keys.reduce((acc, key, i) => {
    acc[key] = datas[i];

    return acc;
  }, {})
};
