import React, {useContext} from "react";
import bg from '../../assets/usine.png';
import firestoreDatasContext from "../firestoreDatas/firestoreDatasContext";
import './style.scss';

const Value = () => {
    const data = useContext(firestoreDatasContext);

    const values = (data && data.values) || null;

    return (
        <section className="main-content ">
            <div className="container">
                <div className="row">
                    <div className="bg" style={{backgroundImage: `url(${bg})`, display:'flex', flexDirection: "column"}}>
                        {
                            values && values.map((val, index) => (
                              <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: "flex-start"}}>
                                  <h2>{data?.lang === "en" ? val.title_en : val.title_fr}</h2>
                                <div
                                    key={`value-${index}`}
                                    dangerouslySetInnerHTML={{__html: data?.lang === "en" ? val.content_en : val.content_fr}}
                                />
                                  {
                                      index < values.length - 1 && <hr style={{width: '100%', color: 'black'}}/>
                                  }
                              </div>
                            ))
                        }

                    </div>
                </div>
            </div>
        </section>
    );
};

export default Value;
