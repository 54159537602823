import { createContext } from "react";
import { LangEnum } from "./langEnum";


export default createContext({
  isLoading: false,
  presentation: null,
  lang: window.localStorage.getItem('lang') === "fr" ? LangEnum.Fr : LangEnum.En,
  brand: 'Hydrae',
  realisations: [],
  technicalAssets: [],
  clients: [],
  values: [],
});
