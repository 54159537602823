import React, {useContext} from "react";
import './style.scss';
import fr from '../../assets/fr.svg';
import en from '../../assets/en.svg';

import firestoreDatasContext from "../firestoreDatas/firestoreDatasContext";
const LanguageSwitcher = () => {
    const data = useContext(firestoreDatasContext);
    return (
        <div className="header__languages">
            <div className="header__lang" onClick={() => {
                data.setLang('fr')
                window.localStorage.setItem('lang', "fr")
            }}
                 style={{textDecoration: data.lang === "fr" ? 'underline' : 'none'}}>
                <img src={fr} alt={"flag"}/>
            </div> -
            <div className="header__lang" onClick={() => {
                window.localStorage.setItem('lang', "en")
                data.setLang('en')
            }}
                 style={{textDecoration: data.lang === "en" ? 'underline' : 'none'}}>
                <img src={en} alt={"flag"} />
            </div>
        </div>
    );
};

export default LanguageSwitcher;
