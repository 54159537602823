import { useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";

const useResize = (func, delay = 300) => {
  const resizeHandler = useDebouncedCallback(func, delay);

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);

    return () => {
      window.removeEventListener("resize", resizeHandler);
    }
  }, [resizeHandler]);
}

export default useResize;
