import React, {useContext} from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../style.scss';
import firestoreDatasContext from "../firestoreDatas/firestoreDatasContext";

const Achievements = ({
    source
}) => {
    const data = useContext(firestoreDatasContext);
    return (
        <section className="main-content ">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-12">
                        <div className="clients-label">
                            { data.lang === "fr" ? "Ils nous font confiance" : "They trust us" }
                        </div>
                        {/*<Slider {...settings} />*/}
                    </div>
                    <div className="clients-container">
                        <div className="row">
                        {
                            data && data[source] && data[source].map((item, index) => (
                                <div
                                    key={index}
                                    className="col-12 col-xs-12 col-md-6 col-lg-6 col-xl-4 mb-30 padding-30"
                                >
                                    <article
                                        style={{border: 'solid 1px rgba(0, 0, 0, .1)'}}
                                        //onClick={() => handleModle(item?.id)}
                                    >
                                        <div  style={{aspectRatio: 1, display: 'flex', justifyContent: "center", alignItems: 'center'}}>
                                                <img src={item.imageUrl} alt="item.title" />
                                        </div>
                                      
                                        <div className="post-content post-content--override">
                                            <div className="entry-header">
                                                <h3>{item.title}</h3>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            ))
                        }
                        </div>
                    </div>

                    {/*
                        target && data && data[source] && data[source][target] && (
                            <div className="description">
                                <div className="col-xl-12 col-lg-12 col-12">
                                    <div className='row'>
                                        <h1>
                                            <span>
                                                {data[source][target].title}
                                            </span>
                                        </h1>
                                    </div>
                                    <div className='row'>
                                        <div
                                            dangerouslySetInnerHTML={{__html: data[source][target].content}}
                                        />
                                    </div>
                                </div>
                            </div>
                        )*/
                    }
                </div>
                {
                    /*
                 <div className={'row'}>
                    <model-viewer alt="Neil Armstrong's Spacesuit from the Smithsonian Digitization Programs Office and National Air and Space Museum" src="https://modelviewer.dev/shared-assets/models/NeilArmstrong.glb" ar ar-modes="webxr scene-viewer quick-look" environment-image="https://modelviewer.dev/shared-assets/environments/moon_1k.hdr" poster="https://modelviewer.dev/shared-assets/models/NeilArmstrong.webp" seamless-poster shadow-intensity="1" camera-controls enable-pan></model-viewer>
                </div>
                     */
                }
            </div>
            <div>
            </div>
        </section>
    );
};

export default Achievements;
/*
<div key={`realisation-${index}`} onClick={() => displayItem(index)}>
    <div className={"round-client"} style={{background: `url(${item.imageUrl})`, backgroundSize: 'cover!important'}}/>
</div>*/
