import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getAllDatas, parseDatasWithTranslations } from "../../helpers/services";
import FirestoreDatasContext from "./firestoreDatasContext";
import { LangEnum } from "./langEnum";


const FirestoreDatas = ({
  children,
}) => {
  const [lang, setLang] = useState(LangEnum.En);
  const [firestoreDatasValues, setFirestoreDatasValues] = useState({
    isLoading: false,
    presentation: null,
    realisations: [],
    technicalAssets: [],
    clients: [],
    values: [],
    isLoaded: false,
  });

  const getAllFirestoreDatas = useCallback(async () => {
    setFirestoreDatasValues({
      ...firestoreDatasValues,
      isLoading: true,
    });

    try {
      const result = await getAllDatas();
      setFirestoreDatasValues({
        ...parseDatasWithTranslations(result, lang),
        isLoaded: true,
        isLoading: false,
      });

      console.log("--- result", result);
    } catch (err) {
      console.error(err);
      setFirestoreDatasValues({
        ...firestoreDatasValues,
        isLoading: false,
        isLoaded: false,
      });
    }
  }, [lang, firestoreDatasValues]);

  useEffect(() => {
    if (!firestoreDatasValues.isLoaded) {
      getAllFirestoreDatas();
      return;
    }

    setFirestoreDatasValues({
      ...parseDatasWithTranslations(firestoreDatasValues, lang)
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const value = useMemo(() => ({
    ...firestoreDatasValues,
    lang,
    setLang,
  }), [firestoreDatasValues, lang, setLang])

  console.log('--- value', value);
  return (
    <FirestoreDatasContext.Provider value={value}>
      {children}
    </FirestoreDatasContext.Provider>
  )
};


export default FirestoreDatas;
