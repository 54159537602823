import { useCallback, useEffect } from "react";
import useResize from "../../hooks/useResize";
import { createShootingStar, createStar, drawShootingStar, drawStar, killShootingStar, randomRange, starFieldSettings } from "./starField.utils";


const useStarField = (canvasRef) => {
  const update = useCallback(() => {
    const canvas = canvasRef.current;

    if (!canvas) {
      return;
    }

    const ctx = canvas.getContext("2d");
    const width = canvas.width = window.innerWidth;
    const height = canvas.height = window.innerHeight;

    ctx.clearRect(0, 0, width, height);
    ctx.fillStyle = "transparent";
    ctx.fillRect(0, 0, width, height);

    if (starFieldSettings.withShootingStar) {
      const interval = Date.now() - starFieldSettings.shootingStarTimer;
  
      if (interval > starFieldSettings.shootingStarEmittingInterval.value) {
        starFieldSettings.shootingStarTimer = Date.now();
        starFieldSettings.shootingStarEmittingInterval.value = randomRange(starFieldSettings.shootingStarEmittingInterval.min, starFieldSettings.shootingStarEmittingInterval.max);
  
        createShootingStar(randomRange(0, width / 2), randomRange(0, height / 2));
      }
    }

    for (const star of starFieldSettings.stars) {
      star.update();
      drawStar(star, ctx);

      if (star.x > width) {
        star.x = 0;
      } else if (star.x < 0) {
        star.x = width;
      }

      if (star.y > height) {
        star.y = 0;
      } else if (star.y < 0) {
        star.y = height;
      }
    }

    for (const shootingStar of starFieldSettings.shootingStars) {
      if (shootingStar.isSpawning) {
        shootingStar.opacity += starFieldSettings.shootingStarOpacityDelta;

        if (shootingStar.opacity >= 1) {
          shootingStar.isSpawning = false;
          killShootingStar(shootingStar);
        }
      }

      if (shootingStar.isDying) {
        shootingStar.opacity -= starFieldSettings.shootingStarOpacityDelta;

        if (shootingStar.opacity <= 0) {
          shootingStar.isDying = false;
          shootingStar.isDead = true;
        }
      }

      shootingStar.trailLengthDelta += starFieldSettings.trailLengthDelta;
      shootingStar.update();

      if (shootingStar.opacity > 0) {
        drawShootingStar(shootingStar, ctx);
      }
    }

    for (let i = starFieldSettings.shootingStars.length - 1; i >= 0; i--) {
      if (starFieldSettings.shootingStars[i].isDead) {
        starFieldSettings.shootingStars.splice(i, 1);
      }
    }

    requestAnimationFrame(update);
  }, [canvasRef]);

  const initialize = useCallback(() => {
    const canvas = canvasRef.current;
    const stars = [];
    const width = canvas.width = window.innerWidth;
    const height = canvas.height = window.innerHeight;

    starFieldSettings.shootingStarTimer = Date.now();

    for (const layer of starFieldSettings.layers) {
      for (let i = 0; i < layer.count; i++) {
        stars.push(createStar(randomRange(0, width), randomRange(0, height), layer));
      }
    }

    starFieldSettings.stars = stars;
    update();
  }, [canvasRef, update]);

  useEffect(() => {
    if (canvasRef.current) {
      initialize(canvasRef);
    }
  }, [canvasRef, initialize]);

  useResize(() => initialize());
};


export default useStarField;
